import styled from 'styled-components'
import { BigWrapper } from '@raylink-overseas/common/components'
import { Media } from '@raylink-overseas/common/theme'

export const AlsoLikeWrap = styled.div`
  width: 100%;
  border-top: 1px solid #e6e6e6;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 63.49%,
      #ffffff 99.94%
    ),
    linear-gradient(360deg, #ffffff -0.1%, rgba(255, 255, 255, 0) 25.86%),
    linear-gradient(
      90deg,
      rgba(219, 240, 255, 0.4) 0%,
      rgba(232, 243, 255, 0.4) 51.4%,
      rgba(206, 226, 255, 0.4) 99.69%
    );
  border-top: none;
  padding-bottom: 112px;
  ${Media.homeLaptop`
    min-width: 1184px;
    padding-bottom: 88px;
  `}
  ${Media.laptop`
    min-width: 1088px;
    padding-bottom: 64px;
  `}
  ${Media.ipad`
    min-width: 896px;
    padding-bottom: 56px;
  `}
  ${Media.phone`
    min-width: 6.86rem;
    padding-bottom: 0;
  `}
`
export const AlsoLikeContent = styled(BigWrapper)`
  .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 50px;
    color: #1f2329;
    text-align: center;
    margin-bottom: 64px;
  }
  .items-box {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
  ${Media.homeLaptop`
    .title {
      margin-bottom: 48px;
      font-size: 34px;
    }
    .items-box {
      gap: 36px;
    }
  `}
  ${Media.laptop`
    .title {
      margin-bottom: 40px;
      font-size: 32px;
    }
  `}
  ${Media.ipad`
    .title {
      margin-bottom: 32px;
      font-size: 24px;
    }
  `}
  ${Media.phone`
    width: 100%;
    min-width: 7.5rem;
    .title {
      font-size: 0.48rem;
      line-height: 0.6rem;
      text-align: center;
      margin-bottom: 0.56rem;
    }
    .items-box {
      flex-direction: column;
      align-items: center;
      padding-bottom: 0.76rem;
      gap: 0.32rem;
    }
  `}
`
