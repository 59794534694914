import React from 'react'
import { Layout } from '@raylink-overseas/common/components'
import TransferBanner from '../components/FeatureBanner'
import ListFlow from '../components/ListFlow'
import Card from '../components/Card'
import StepSlide from '../components/StepSlide'
import Question from '../components/Question'
import StartNow from '../components/StartNow'

import PrivacyMain from '../images/privacy-main.png'
import PrivacyMainMobile from '../images/PrivacyModeBgMobile.png'
import BlackenScreen from '../images/blacken-screen.png'
import CrossPlatformSupport from '../images/cross-platform-support.png'
import PrivacyRemoteWork from '../images/PrivacyRemoteWork.png'
import Entertainment from '../images/Entertainment.png'
import Maintenance from '../images/Maintenance.png'
import DownloadInstall from '../images/DownloadInstall.png'
import EstablishRC from '../images/UnattenedAccess.png'
import ActivatePrivacyMode from '../images/ActivatePrivacyMode.png'

const PrivacyMode = () => {
  const descList = [
    'Blacken the screen and mute the device on the host.',
    'Trigger Privacy Mode with a single click.',
    'Support remote access from Windows, Mac, iOS, and Android devices.',
  ]

  const flowItemList = [
    {
      img: BlackenScreen,
      img_mobile: BlackenScreen,
      title: 'Blacken Remote Screen',
      desc: "By activating Avica Privacy Mode, the remote PC's screen instantly turns black, and its audio is automatically muted; all visuals and sounds of the remote PC can only be viewed and heard from your local device. This guarantees the utmost privacy for users, keeping your work and entertainment activities entirely hidden from prying eyes and ears.",
      ifRotation: false,
      ifShowDownloadBtn: false,
    },
    {
      img: CrossPlatformSupport,
      img_mobile: CrossPlatformSupport,
      title: 'Cross-Platform Support',
      desc: "Experience seamless control and privacy across multiple platforms with Avica Privacy Mode. Whether you're remotely accessing a Windows PC from Mac, iOS, Android, or another Windows device, the one-click Privacy Mode feature allows you to instantly initiate full privacy protection on the remote PC.",
      ifRotation: true,
      ifShowDownloadBtn: false,
    },
  ]

  const cardList = [
    {
      img: PrivacyRemoteWork,
      title: 'Remote Work',
      desc: "No longer do you have to worry about your confidential documents being inadvertently seen or your progress being monitored. Avica's Privacy Mode empowers you to maintain the utmost privacy and security while conducting your work tasks in a remote environment.",
    },
    {
      img: Entertainment,
      title: 'Entertainment',
      desc: "Whether you're streaming movies, playing games, or accessing personal files on your private computer, activate Privacy Mode to ensure that your personal digital activities remain untouched and concealed from prying eyes. Embrace the freedom to enjoy a private entertainment experience!",
    },
    {
      img: Maintenance,
      title: 'Maintenance',
      desc: "Whether you're remotely servicing the cash register computers at a store or monitoring the operation of enterprise's devices, Privacy Mode ensures that your maintenance process remains hidden from others. Focus on delivering exceptional remote maintenance services while keeping your work private and confidential.",
    },
  ]

  const tab1List = [
    {
      img: DownloadInstall,
      title: 'Step 1. Download and Install',
      desc: 'Download and install the Avica on your remote PC and local device. Launch Avica and sign in.',
      redirect: false,
    },
    {
      img: EstablishRC,
      title: 'Step 2. Establish Remote Connection',
      desc: 'Enter the remote PC\'s Avica ID on the local device and click the "Connect" button. Then, you can use Password-Free Connection or enter the Password to establish a remote connection.',
      redirect: false,
    },
    {
      img: ActivatePrivacyMode,
      title: 'Step 3. Activate Privacy Mode',
      desc: "On the local device, click the Privacy Mode button on the toolbar at the top of the interface and now your remote PC's screen is blackened.",
      redirect: true,
    },
  ]

  const More = [tab1List]

  const tabInfoList = [
    {
      tabName: 'File Transfer Tool',
      ifShowSelectBox: true,
      isPlay: true,
    },
  ]

  const faqList = [
    {
      question:
        'Why is the remote device not compatible with Avica Privacy Mode?',
      answer:
        'The current Avica Privacy Mode only supports Windows devices as remote devices. If you receive a system prompt stating that your remote device does not support the privacy screen function, please confirm whether your remote device is a Windows system.',
    },
    {
      question: 'How can I disable Avica Privacy Mode?',
      answer:
        'When Privacy Mode is enabled, the Privacy Mode button in the top toolbar of the local device interface will turn blue. To disable Privacy Mode, simply click this button again.',
    },
  ]

  return (
    <Layout pageType="privacyMode">
      <TransferBanner
        title="Privacy Mode"
        descList={descList}
        photo={PrivacyMain}
        mobile={PrivacyMainMobile}
      />
      <ListFlow
        featureList={flowItemList}
        title="Keep Your Remote Session Private and Secure"
        className="no-desc-title"
      />
      <StepSlide
        title="How to Enable Avica Privacy Mode?"
        ifShowTab={false}
        More={More}
        TabInfo={tabInfoList}
        className_title="title"
        guideLink="https://www.avica.link/help-center/privacy-mode/"
      />
      <Card cardList={cardList} title="Use Avica Privacy Mode in Any Scene" />
      <Question faqList={faqList} />
      <StartNow
        pre="Start Your Highly Secure and"
        span="Private Remote Session"
        suf=""
      />
    </Layout>
  )
}

export default PrivacyMode
