import React from 'react'
import { CardItemWrap } from './atoms'

interface Props {
  data: {
    img: string
    title: string
    desc: string
  }
  hasBottom?: boolean
  className?: string
}

const BlogItem = ({ data, ...rest }: Props) => {
  return (
    <CardItemWrap {...rest}>
      <img className="cover" src={data.img} alt="" loading="lazy" />
      <div className="info-wrap">
        <h3 className="title">{data.title}</h3>
        <div className="info-text">{data.desc}</div>
      </div>
    </CardItemWrap>
  )
}

export default BlogItem
