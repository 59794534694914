import styled from 'styled-components'
import { Media } from '@raylink-overseas/common/theme'
import { MultipleLineEllipsis } from '@raylink-overseas/common/components'

export const CardItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  .cover {
    border-radius: 24px;
    background: #d7e0ec;
    margin-bottom: -55px;
  }
  .info-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    height: 100%;
    padding: 32px 40px 44px;
    background: #ffffff;
    border-radius: 24px;
    background: linear-gradient(
      180deg,
      rgba(240, 242, 255, 0.8) 0%,
      #ffffff 19.8%
    );
    box-shadow: 0px 0px 40px 0px rgba(69, 96, 255, 0.04);
    backdrop-filter: blur(16px);
    .title {
      height: 41px;
      ${MultipleLineEllipsis(2)}
      color: #1f2329;
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      text-align: left;
      margin-bottom: 0;
    }
    .info-text {
      display: flex;
      justify-content: flex-start;
      color: #595959;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      flex: 1;
      align-self: center;
    }
  }
  ${Media.homeLaptop`
    width: 426px;
  `}
  ${Media.laptop`
    width: 346px;
    .info-wrap {
      .title {
        font-size: 25px;
      }
      .info-text {
        font-size: 18px;
        line-height: normal;
      }
    }
  `}
  ${Media.ipad`
    width: 288px;
    .info-wrap {
      gap: 8px;
      padding: 28px 24px 45px;
      .info-text {
        font-size: 14px;
      }
    }
  `}
  ${Media.phone`
    width: 6.86rem;
    .cover {
      border-radius: 0.32rem;
      margin-bottom: -45px;
    }
    .info-wrap {
      gap: 0.12rem;
      padding: 0.56rem 0.48rem 0.48rem 0.48rem;
      .title {
        max-height: 0.56rem;
        font-size: 0.44rem;
        line-height: 0.56rem;
        color: #1f2329;
      }
      .info-text {
        font-size: 0.28rem;
        line-height: 0.42rem;
        color: #595959;
      }
    }
  `}
`
