import React from 'react'
import CardItem from '../CardItem'
import { AlsoLikeWrap, AlsoLikeContent } from './atoms'

interface Props {
  cardList: {
    img: string
    title: string
    desc: string
  }[]
  title: string
}

const Card = ({ cardList, title }: Props) => {
  return (
    <AlsoLikeWrap>
      <AlsoLikeContent>
        <h2 className="title">{title}</h2>
        <div className="items-box">
          {cardList?.map(item => (
            <CardItem data={item} key={item.title} />
          ))}
        </div>
      </AlsoLikeContent>
    </AlsoLikeWrap>
  )
}

export default Card
